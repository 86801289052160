import * as React from 'react'
import { createContext, useContext, useState } from 'react'
import { useShopData } from './ShopDataProvider/ShopDataProvider'

interface AnnouncementContextValue {
  visible: boolean
  message: any
  showCloseButton: boolean
  cta: any
  enableMarquee: boolean
  colorTheme: string | null | undefined
  setVisible: (visible: boolean) => void
  setMessage: (message: any) => void
  setShowCloseButton: (show: boolean) => void
  setEnableMarquee: (enable: boolean) => void
  setColorTheme: (color: string | null | undefined) => void
}

const AnnouncementContext = createContext<AnnouncementContextValue | undefined>(
  undefined,
)

export const useAnnouncement = () => {
  const context = useContext(AnnouncementContext)

  if (!context) {
    throw new Error(
      'useAnnouncement must be used within an AnnouncementProvider',
    )
  }
  return context
}

interface Props {
  children: React.ReactNode
}

export const AnnouncementProvider: React.FC<Props> = ({ children }) => {
  const { siteSettings } = useShopData()
  const announcementBanner = siteSettings?.announcementBanner

  const [visible, setVisible] = useState(Boolean(announcementBanner))
  const [message, setMessage] = useState(announcementBanner?.contentRaw)
  const [showCloseButton, setShowCloseButton] = useState(
    Boolean(announcementBanner?.showCloseButton),
  )
  const [cta, setCta] = useState(announcementBanner?.cta)
  const [enableMarquee, setEnableMarquee] = useState(
    Boolean(announcementBanner?.enableMarquee),
  )
  const [colorTheme, setColorTheme] = useState(announcementBanner?.colorTheme)

  return (
    <AnnouncementContext.Provider
      value={{
        visible,
        message,
        showCloseButton,
        cta,
        enableMarquee,
        colorTheme,
        setVisible,
        setMessage,
        setShowCloseButton,
        setEnableMarquee,
        setColorTheme,
      }}
    >
      {children}
    </AnnouncementContext.Provider>
  )
}
